.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login {
  background-color: #073763;
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.close {
  color: #fff;
}

/* .react-datepicker-wrapper, .react-datepicker__input-container {
  display: block;
} */

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
}

.anchor-action:link {
  color: #0052cc;
}

.anchor-action:visited {
  color: #0052cc;
}

.anchor-action:hover {
  color: #3b73af;
  text-decoration: underline;
}

.parent {
  height: 200px;
}

/* This will style any <img> element in .parent div */
.parent img {
  height: 100%;
  width: 100%;
}

.main-bg {
  background-color: #fafbfd;
}

.inner-bg {
  background-color: #fff;
}

.upload-panel {
  border: 1px solid #d3dae6;
  border-radius: 4px;
}

.text-jira {
  color: #0052cc;
}

.anchor-action-edit:hover {
  color: blue;
}

.anchor-action-delete:hover {
  color: #dc3545;
}

.navbar-brand img {
  /* put value of image height as your need */
  float: left;
  margin-right: 7px;
  height: 50px;
  max-width: 100px;
}

.navbar {
  display: block;
}

.navbar-brand {
  display: block;
}
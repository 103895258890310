@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/variables';

$theme-colors: (
  'primary': #073763,
);

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

@each $color, $value in $theme-colors {
  @include bg-variant('.bg-#{$color}', $value);
}

.text-primary {
  color: #073763 !important;
}

.text-secondary {
  color: #9da6aa !important;
}


.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #073763;
  border-color: #073763;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #073763;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #073763;
}

.modal-header {
  padding:9px 15px;
  border-bottom:1px solid #eee;
  background-color: #073763;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
   border-top-left-radius: 3px;
   border-top-right-radius: 3px;
}

.badge {
  font-size: 90%;
}
